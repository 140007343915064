.list-demo-actions-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: all 0.1s;
  }
  
  .list-demo-actions-icon:hover {
    background-color: var(--color-fill-3);
    cursor: pointer;
  }

  #report-issue-drawer .arco-drawer-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }