.drawerBtn {
    display: none;
}

@media screen and (max-width: 1024px) {
    .navMenu {
        display: none;
    }
    .drawerBtn {
        display: block ;
        cursor: pointer;
        position: fixed;
        margin: 10px;
        left: 0;
    }
}