:root {
  --color-background-primary: #f7f7f7;
  --color-background-secondary: #dedede;
  --color-background-code-block: #222325;
  --color-border: #dedede;
  --color-selection-background: #222325;
  --color-selection-text: #f7f7f7;
  --color-text-primary: #222325;
  --color-text-secondary: #6f7176;
  --color-text-inverse: #f7f7f7;
  --color-link-default: var(--color-text-primary);
  --color-link-default-hover: var(--color-text-secondary);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

kbd {
  --kbd-color-background: var(--color-background-primary);
  --kbd-color-border: #797979;
  /* --kbd-color-text: var(--color-text-primary); */
  /* background-color: var(--kbd-color-background); */
  border-radius: 0.25rem ;
  border: 1px solid var(--kbd-color-border) ;
  box-shadow: 0 2px 0 1px var(--kbd-color-border) ;
  color: var(--kbd-color-text);
  cursor: default;
  display: inline-block;
  font-family: var(--font-family-sans-serif) !important;
  font-size: 0.875em !important;
  line-height: 1;
  min-width: 0.75rem;
  padding: 2px 5px;
  position: relative;
  text-align: center;
  top: -1px;
  
}

kbd:hover {
  --kbd-color-border: #797979;
  box-shadow: 0 1px 0 0.5px var(--kbd-color-border) ;
  top: 1px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;